<template>
  <div class="header" :class="{ 'special-header': specialHeader }">
    <div class="header-main flex-align-between">
      <div class="header-left flex-align">
        <a href="/" rel="noopener noreferrer" class="logo-bd flex-align">
          <img src="@/assets/jmlogo.jpg" alt />
        </a>
      </div>
      <ul class="nav-right flex-align">
        <li v-for="(nav, index) in navs" :key="index" :class="{ 'leader-nav': role === 'leader' }">
          <a href="javascript:;" @click="jumpPage(nav)" :class="{ active: activeNavName === nav.name }" class="nav-item">
            {{ nav.name }}
          </a>
        </li>
        <!-- <li v-if="role === 'teacher' || role === 'expert'">
          <el-dropdown trigger="click" class="csp">
            <span class="nav-item precision-teach-li">
              精准教学
            </span>
            <el-dropdown-menu slot="dropdown" class="precision-dropdown">
              <el-dropdown-item @click.native="getPrecisionTeach('beike')">去备课</el-dropdown-item>
              <el-dropdown-item @click.native="getPrecisionTeach('inclass')">去上课</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li> -->
        <!-- 可视化平台 -->
        <li v-if="role === 'leader'" :class="{ 'leader-nav': role === 'leader' }">
          <el-dropdown trigger="click" class="csp">
            <span class="nav-item">
              可视化平台
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="toDataScreen(0)">总览数据大屏</el-dropdown-item>
              <el-dropdown-item @click.native="toDataScreen(1)">阅读数据大屏</el-dropdown-item>
              <el-dropdown-item @click.native="toDataScreen(2)">全国数据大屏</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <!-- 演示首师优字 -->
        <!-- <li :class="{ 'leader-nav': role === 'leader' }">
          <el-dropdown trigger="click" class="csp">
            <span class="nav-item">
              互动课堂
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="goSsyz(1)">识写课程</el-dropdown-item>
              <el-dropdown-item @click.native="goSsyz(2)">阅读课程</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li> -->
        <li v-if="userInfo.id == '45291'" @click="goSsyz">
          <a href="javascript:;" class="nav-item">
            首师优字
          </a>
        </li>
        <li v-if="$store.state.user.userInfo.schoolId" :class="{ 'leader-nav': role === 'leader' }">
          <el-dropdown trigger="click" class="csp">
            <span class="nav-item">空间</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="goschoolSpace">学校空间</el-dropdown-item>
              <el-dropdown-item v-if="role === 'leader'" @click.native="goalliance">盟市空间</el-dropdown-item>
              <el-dropdown-item v-if="role === 'leader'" @click.native="goCountySpace">旗县空间</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>

        <!-- 应用中心 -->
        <li v-if="JSON.stringify(appList) !== '[]'" :class="{ 'leader-nav': role === 'leader' }">
          <el-dropdown trigger="click" class="csp">
            <span class="nav-item">
              应用中心
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in appList" :key="index" @click.native="goThirdApp(item)">
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <!-- 用户头像 -->
        <li class="avatar" :class="{ 'leader-avatar': role === 'leader' }">
          <el-dropdown trigger="click" class="csp">
            <img :src="userInfo && this.downloadURL + userInfo.avatar" :onerror="$store.state.user.defaultAvatar" alt />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="$router.push('/my')">个人中心</el-dropdown-item>
              <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  mapState
} from "vuex"
export default {

  data() {
    return {
      specialHeader: false,
      activeNavName: "首页",
      currentNav: [],
      navs: [{
        name: "首页",
        href: "/item-list",
      },
      {
        name: "能力体系",
        href: "/ability",
      },
      // {
      //   name: "高考模拟",
      //   href: "/simulate"
      // },
      {
        name: "智慧学堂",
        href: "/school",
      },
      {
        name: "资源圈",
        href: "/resources",
      },
      {
        name: "教学督导",
        href: "/supervise",
      },
      ],
      appList: [],
      areaMsg: undefined,
    };
  },
  computed: {
    ...mapState({
      activeModuleHref: state => state.basics.activeModuleHref,
      userInfo: state => state.user.userInfo,
      role: state => state.user.role,
      currentProject: state => state.user.currentProject,
      roleId: state => state.user.roleId,
      token: state => state.user.token
    })
  },
  methods: {
    // 获取应用中心列表
    getAppList() {
      let params = {
        projectId: this.currentProject.id,
      }
      this.$Api.Home.getAppList(params).then(res => {
        console.log(res);
        if (res.data) {
          this.appList = res.data
        }
      }).catch(err => {
        console.log(err);
        this.$message.error('暂无权限，请联系管理员')
      })
    },
    // 跳转第三方应用
    goThirdApp(item) {
      // console.log(item.url);
      this.getldentifying(item.url);
    },
    getldentifying(url) {
      let params = {
        phone: this.userInfo.phone,
        userId: this.userInfo.id,
        projectId: this.currentProject.id,
        userRole: this.roleId
      }
      this.$Api.Home.getldentifying(params).then(res => {
        if (res.data) {
          let str = url.substring(0, 4);
          if (str == 'http') {
            window.open(`${url}?code=${res.data}`)
          } else {
            window.open(`http://${url}?code=${res.data}`)
          }
          // if (url.indexOf("https") == -1 || url.indexOf("http") == -1) {
          //   window.open(`http://${url}?code=${res.data}`)
          // } else {
          //   window.open(`${url}?code=${res.data}`)
          // }
        }
      }).catch(err => {
        console.log(err);
        this.$message.error('暂无权限，请联系管理员')
      })
    },
    // 跳转首师优字
    goSsyz(type) {
      this.getAuthorization()
        .then(res => {
          console.log(res);
          console.log(`https://yun.cnuzi.com/logins?token=${res}&source=yanxiu&type=${type}`);
          // window.open(`https://ssyzpccdn.lezhireading.com/logins?token=${res}&source=yanxiu&type=${type}`);//优字测试服
          window.open(`https://yun.cnuzi.com/logins?token=${res}&source=yanxiu&type=${type}`) //优字正式服
        })
        .catch(err => {
          console.log(err);
          this.$message.error('暂未开通')
        })
    },
    // 跳转至其他页面
    jumpPage(item) {
      this.activeNavName = item.name;
      this.$store.commit('getActiveModuleHref', item.href)
      this.$router.push(item.href);
    },
    // 登出
    logout() {
      this.$Api.Home.logout("")
        .then((resData) => {
          // 请求正常
          // 如果是第三方登出 则返回到第三方地址 否则  返回至登录页
          if (
            resData.code == 200 &&
            sessionStorage.getItem("loginPath") === "third" && resData.data.url
          ) {
            sessionStorage.clear();
            this.$store.dispatch('resetState');
            window.location.href = `${resData.data.url}https://${window.location.host}/login`;
          } else {
            sessionStorage.clear();
            this.$store.dispatch('resetState');
            this.$router.push("/login");
          }

        })
        .catch((res) => {
          // 请求失败 直接清除session数据返回登录页
          sessionStorage.clear();
          this.$store.dispatch('resetState');
          this.$router.push("/login");
        });
    },
    // 精准教学
    async getPrecisionTeach(type) {
      let params = {
        id: 1,
      };
      let resData = await this.$Api.Home.getAuthKey(params);
      // console.log(resData);
      window.open(
        `${resData.data.url}&ticket=${resData.data.ticket}&lesson=${type}`
      );
    },
    // 去数据大屏  0：总览数据大屏 1：阅读数据大屏 2：全国数据大屏
    toDataScreen(type) {
      if (type == 2) {
        window.open("https://dctest.lezhireading.com/#/country");
        return;
      }
      let param = !type ? "yinchuan" : "";
      window.open(
        `https://datav.lezhireading.com/#/${param}`
      );
    },
    // 获取互动课堂权限
    getAuthorization() {
      return this.$Api.Activity.getGoYzHomeUrl()
        .then(res => {
          console.log('获取权限成功', res);
          if (res.code == 200) {
            return res.data.split('=')[1]
          } else {
            return Promise.reject('暂无权限')
          }
        })
        .catch(err => {
          console.log('获取权限失败', err);
          return Promise.reject('暂无权限')
        })
    },
    // 获取应用中心列表
    getAppList() {
      let params = {
        projectId: this.currentProject.id,
      }
      this.$Api.Home.getAppList(params).then(res => {
        // console.log(res);
        if (res.data) {
          this.appList = res.data;
        }
      }).catch(err => {
        console.log(err);
        this.$message.error('暂无权限，请联系管理员')
      })
    },
    //跳转学校空间
    goschoolSpace() {
      // console.log('store.state.user.token', this.$store.state.user.token)
      window.sessionStorage.setItem("token", this.token);
      let text = this.$router.resolve({
        path: "/schoolSpace",
        query: {
          schoolId: this.userInfo.schoolId,
          schoolName: this.userInfo.schoolName,
        },
      });
      window.open(text.href, "_blank");
    },
    // 获取旗县ID
    async getAreaId() {
      let param = {
        schoolId: this.userInfo.schoolId
      }
      let resData = await this.$Api.dataScreen.getAreaId(param);
      // console.log('areaId', resData);
      if (resData.data.orgId) {
        this.areaMsg = resData.data;
      } else {
        this.areaMsg = resData.msg
      }

    },
    // 跳转至旗县空间
    goCountySpace() {
      if (typeof this.areaMsg == 'string') {
        this.$message.error('暂无权限，请联系管理员')
        return;
      }
      window.sessionStorage.setItem("token", this.token);
      let text = this.$router.resolve({
        path: "/county",
        query: {
          areaId: this.areaMsg.orgId,
          county: this.areaMsg.name,
        },
      });
      window.open(text.href, "_blank");
    },
    // 跳转至盟市空间
    goalliance() {
      if (typeof this.areaMsg == 'string') {
        this.$message.error('暂无权限，请联系管理员')
        return;
      }
      window.sessionStorage.setItem("token", this.token);
      let text = this.$router.resolve({
        path: "/alliance",
        query: {
          schoolId: this.userInfo.schoolId
        },
      });
      window.open(text.href, "_blank");
    }
  },
  watch: {
    "currentProject": {
      handler(newVal, oldVal) {
        // console.log('当前项目信息', newVal);
        if (JSON.stringify(newVal) !== '{}') {
          this.getAppList();
          // this.judgeuserInfo();
        } else {
          this.appList = [];

        }
      },
      immediate: true,
    },
    "role": {
      handler(newVal, oldVal) {
        if (
          newVal !== "leader" &&
          this.navs[this.navs.length - 1].name == "教学督导"
        ) {
          this.navs.pop();
        } else if (
          newVal == "leader" &&
          this.navs[this.navs.length - 1].name != "教学督导"
        ) {
          this.navs.push({
            href: "/supervise",
            name: "教学督导",
          });
        }

        if (newVal) {
          this.getAreaId();
        }

      },
      immediate: true,
    },
    $route(to, from) {
      // console.log('to--', to);
      // console.log('from--', from);
      this.specialHeader = to.path == "/item-list";
      // 标蓝位置
      // 循环导航列表,如果列表中某一项与回话中存储的path 相同
      // 赋值并终止循环
      for (let i = 0; i < this.navs.length; i++) {
        if (this.activeModuleHref == this.navs[i].href) {
          this.activeNavName = this.navs[i].name;
          break;
        }
      }
      // 循环导航列表，如果当前path包含列表中某一项 存储该项到回话
      // 赋值并终止循环
      for (let i = 0; i < this.navs.length; i++) {
        // 如果当前path 存在 于导航列表中
        // 赋值并终止循环
        if (to.path.indexOf(this.navs[i].href) !== -1) {
          this.$store.commit('getActiveModuleHref', this.navs[i].href)
          this.activeNavName = this.navs[i].name;
          break;
        }
      }
    },
  },
  mounted() {
    this.specialHeader = location.pathname == "/item-list";
    // this.judgeuserInfo();


  },
};
</script>

<style lang="less" scoped>
.header {
  font-size: 16px;
  background-color: #fff;
  min-width: 990px;
  box-shadow: 0px 2px 10px 0px #F5F8FA;

  .header-main {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 990px;
    line-height: 90px;
  }

  .header-left {
    border-bottom: 0;

    h1 {
      position: relative;

      .logo-bd {
        display: block;
        padding-top: 40px;
        width: 370px;
        height: 0px;
        overflow: hidden;
        text-indent: -9999px;
        background: url("../../assets/images/home/cun_new_logo_copy.png") 0 0 no-repeat;
      }
    }
  }

  .active {
    color: #508ef9 !important;
  }

  .nav-right {
    li {
      position: relative;

      .nav-item {
        display: block;
        padding: 0 20px;
        line-height: 90px;
        font-size: 15px;
        color: #333;
        font-weight: 600;
      }

      &:first-child .nav-item {
        padding-left: 0;
      }

      &.leader-nav {
        .nav-item {
          padding: 0 7px;
        }
      }

      &.avatar {
        cursor: pointer;
        margin-left: 20px;

        &.leader-avatar {
          margin-left: 7px;
        }

        a.router-link-active {
          background-color: #fff;
        }

        img {
          vertical-align: middle;
          border-radius: 50%;
          width: 40px;
          height: 40px;
        }
      }
    }

    // .independent {
    //   color: #fff;
    //   display: block;
    //   padding: 0 20px;
    //   line-height: 90px;
    //   font-size: 15px;
    //   font-weight: 600;
    //   cursor: pointer;
    // }
  }

  &.special-header {
    background-color: transparent;

    .header-left {
      filter: contrast(0) brightness(500);
      -webkit-filter: contrast(0) brightness(500);
    }

    .nav-right li .nav-item {
      color: #fff;
    }
  }

  @media screen and (min-width: 1250px) {
    .header-main {
      max-width: 1200px;
    }

    .nav-right {
      li {
        .nav-item {
          padding: 0 30px;
        }

        &.leader-nav {
          .nav-item {
            padding: 0 15px;
          }
        }

        &.avatar {
          margin-left: 30px;

          &.leader-avatar {
            margin-left: 15px;
          }

          img {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
  }
}
</style>